<template>
    <div class="heritage_monomer">
        <taskCommon
            title="要素单体"
            :currentTree="currentTree"
            :list="list"
            :listConfig="listConfig"
            :snSearch="snSearch"
            :defaultProps="defaultProps"
            @handleChange="handleChange"
        >
            <Mymap
                :mapData="mapData"
                class="map"
                ref="myMap"
                @initmap="initMap"
            ></Mymap>
            <Dialog
                ref="dialog"
                :dialogData.sync="dialogData"
                @updateMap="updateMap"
            ></Dialog>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./heritage_monomer_dialog";
import { mapActions } from "vuex";
export default {
    name: "heritage_monomer",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 602,
            basicMap: null,
            listConfig: {
                // name: 'YCYSMC',
                name: "MC",
                value: "YCYSBM",
                children:"CHILDREN",
            },
            defaultProps: {
                children: "CHILDREN",
                label: "MC",
                value: "YCYSBM",
            },
            featureGroup: null,
        };
    },
    mounted() {},
    methods: {
        updateMap() {
            this.featureGroup.clearLayers();
            this.initMap(this.basicMap);
        },
        ...mapActions(["GetBhOrDtYcysList"]),
        async getList() {
            let result = await this.GetBhOrDtYcysList({ ItemCode: "14" });
            this.list = result.ResultValue.sort((item1, item2) => {
                return item2.JCSJCOUNT - item1.JCSJCOUNT;
            });
            this.list.forEach((item) => {
                item.disabled = item.JCSJCOUNT === 0 ? true : false;
            });
            if (
                this.$route &&
                this.$route.params &&
                this.$route.params.data &&
                this.$route.params.data.jcdxid
            ) {
                this.handleList(this.list, this.$route.params.data.jcdxid);
            }
        },
        handleList(arr, value) {
            for (let i = 0; i < arr.length; i++) {
                if (value === arr[i].ID) {
                    this.$refs.myMap.changeFitBounds(arr[i].WD, arr[i].JD);
                    break;
                } else if (arr[i].CHILDREN && arr[i].CHILDREN.length > 0) {
                    this.handleList(arr[i].CHILDREN, value);
                }
            }
        },
        async initMap(map) {
            await this.getList();
            let _this = this;
            let markers = [];
            // eslint-disable-next-line
            let myIcon1 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_default.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line
            let myIcon2 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_choose.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line
            let myIcon3 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_otherp.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line
            let myIcon4 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_otherg.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            if (this.list && this.list.length > 0) {
                this.list.forEach((item0) => {
                    if (item0.CHILDREN && item0.CHILDREN.length > 0) {
                        item0.CHILDREN.forEach((item) => {
                            if (item.JD && item.WD) {
                                // eslint-disable-next-line
                                let marker = L.marker([item.WD, item.JD], {
                                    icon: item.JCSJCOUNT
                                        ? item.JCPZCOUNT
                                            ? myIcon3
                                            : myIcon1
                                        : myIcon4,
                                    attribution: item,
                                });
                                marker
                                    .on("mouseover", (e) => {
                                        // eslint-disable-next-line
                                        L.popup({
                                            closeOnClick: false,
                                            closeButton: false,
                                            offset: [0, 5],
                                            minWidth: 12,
                                        })
                                            .setLatLng([item.WD, item.JD])
                                            .setContent(item.MC)
                                            .openOn(map);
                                        marker.setIcon(myIcon2);
                                    })
                                    .on("mouseout", (e) => {
                                        map.closePopup();
                                        marker.setIcon(
                                            item.JCSJCOUNT
                                                ? item.JCPZCOUNT
                                                    ? myIcon3
                                                    : myIcon1
                                                : myIcon4
                                        );
                                    })
                                    .on("click", (e) => {
                                        if (item.JCSJCOUNT) {
                                            this.currentTree = item.YCYSBM;
                                            this.$refs.dialog.getDetail(
                                                this.currentTree
                                            );
                                            this.dialogData.title =
                                                item.MC + "-监测任务配置";
                                            this.dialogData.dialog = true;
                                        }
                                    });
                                if (_this.currentTree === item.YCYSBM) {
                                    this.$refs.myMap.changeFitBounds(
                                        item.WD,
                                        item.JD
                                    );
                                    L.popup({
                                        closeOnClick: false,
                                        closeButton: false,
                                        offset: [0, 5],
                                        minWidth: 12,
                                    })
                                        .setLatLng([item.WD, item.JD])
                                        .setContent(item.MC)
                                        .openOn(map);
                                }
                                markers.push(marker);
                                if (item.JCPZCOUNT) {
                                    // eslint-disable-next-line
                                    let divIcon = L.divIcon({
                                        className: "my-div-icon",
                                        html:
                                            '<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">' +
                                            item.JCPZCOUNT +
                                            "</div>",
                                        iconAnchor: [-3, 25],
                                    });
                                    // eslint-disable-next-line
                                    let numMarker = L.marker(
                                        [item.WD, item.JD],
                                        {
                                            icon: divIcon,
                                            attribution: item,
                                        }
                                    );
                                    markers.push(numMarker);
                                }
                            }
                        });
                    }
                });
            }
            // eslint-disable-next-line
            this.featureGroup = L.featureGroup(markers).addTo(map);
            this.basicMap = map;
        },
        changeList(val) {
            // if (this.list[val[2]] && this.list[val[2]].JCSJCOUNT) {
            // if (
            //     (this.list[val[2]].CHILDREN[val[5]] &&
            //         this.list[val[2]].CHILDREN[val[5]].JCSJCOUNT) ||
            //     (this.list[val[2]].CHILDREN[val[5]].CHILDREN[val[6]] &&
            //         this.list[val[2]].CHILDREN[val[5]].CHILDREN[val[6]]
            //             .JCSJCOUNT)
            // ) {
                this.currentTree = val[0];
                this.$refs.myMap.changeFitBounds(val[4].WD, val[4].JD);
                this.$refs.dialog.getDetail(this.currentTree);
                this.dialogData.title = val[1] + "-监测任务配置";
                this.dialogData.dialog = true;
            // }
        },
    },
};
</script>
<style lang="scss" scoped>
.heritage_monomer {
    .my-div-icon {
    }
}
</style>